import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useField } from '@unform/core'

import './styles.css'

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  containerStyle?: React.CSSProperties
  label: string
  tip?: React.ReactChild
  validate?: (value: string) => string | null
  validateWhileType?: boolean
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  containerStyle = {},
  label,
  tip = '',
  validate = () => undefined,
  validateWhileType = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const { defaultValue, error: unformError, fieldName, registerField } = useField(name)

  const [error, setError] = useState(unformError)

  const handleValidate = useCallback(async value => {
    const errorMessage = await validate(value) || ''

    setError(!!errorMessage ? errorMessage : '')
  }, [validate])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!inputRef.current?.value)

    if (inputRef.current?.value) {
      handleValidate(inputRef.current?.value ?? '')
    }
  }, [])

  const handleInputChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
    if (validateWhileType) {
      handleValidate(e.target.value ?? '')
    }
  }, [handleValidate, validateWhileType])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  useEffect(() => {
    if (defaultValue) {
      handleValidate(true)
    }
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, inputRef.current, registerField])

  return (
    <div
      className="app-input-wrapper"
    >
      <label htmlFor={fieldName}>{label}</label>

      <div
        className={[
          isFocused && 'app-input-wrapper-focused',
          isFilled && 'app-input-wrapper-filled',
          !!error && 'app-input-wrapper-errored',
        ].filter(Boolean).join(' ')}
      >
        <input
          id={fieldName}
          defaultValue={defaultValue}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          ref={inputRef}
          {...rest}
        />
      </div>

      {(!!error || !!tip) && (
        <span className={!!error ? 'errored' : ''}>
          {!!error ? error : tip}
        </span>
      )}
    </div>
  )
}

export { InputField }