import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useField } from '@unform/core'

import './styles.css'

interface ToggleInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  uncheckedLabel?: string
  checkedLabel?: string
}

const ToggleInput: React.FC<ToggleInputProps> = ({
  label,
  name,
  uncheckedLabel = 'No',
  checkedLabel = 'Yes',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isChecked, setIsChecked] = useState(false)

  const { defaultValue = false, fieldName, registerField } = useField(name)

  const toggleValue = useCallback(() => {
    setIsChecked(oldState => !oldState)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    })
  }, [fieldName, inputRef.current, registerField])

  return (
    <div className="app-toggle-input-wrapper">
      <span>{label}</span>

      <div
        className={[
          "toggle-input",
          isChecked && 'checked'
        ].filter(Boolean).join(' ')}
      >
        <input
          id={fieldName}
          defaultChecked={defaultValue}
          type="checkbox"
          checked={isChecked}
          onClick={toggleValue}
          ref={inputRef}
          {...rest}
        />
        <label htmlFor={fieldName}>
          <span />
        </label>

        <span>{isChecked ? checkedLabel : uncheckedLabel}</span>
      </div>
    </div>
  )
}

export { ToggleInput }
