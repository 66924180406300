import React, { useCallback, useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import { Form } from "@unform/web"
import { FormHandles, SubmitHandler } from "@unform/core"

import { InputField } from "../../components/InputField"
import { ToggleInput } from "../../components/ToggleInput"
import { Layout } from "../../components/Layout"

import { calculateROI, logAmplitudeEvent, StringValidator } from "../../utils"

import "./styles.css"
import { Helmet } from "react-helmet"

type FormData = {
  numOfTicketsPerMonth: string
  percentageOfScalableTickets: string
  numOfAgents: string
  manualAgentTickets: boolean
  averageResolutionTimeInHours: string
  averageHourlyRateForAgent: string
  email: string
  phoneNumber: string
}

export default function ROICalculator() {
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  const formRef = useRef<FormHandles>(null)

  const validateNumOfTicketsPerMonth = useCallback((value: string) => {
    const validators = new StringValidator(value, "numOfTicketsPerMonth")

    const { errors: validations, path } = validators
      .required("This input is required")
      .isNumber("Value must be a number")
      .min(0, "Value must be greater than 0")
      .isInteger("Value must be an integer")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const validatePercentageOfScalableTickets = useCallback((value: string) => {
    const validators = new StringValidator(value, "percentageOfScalableTickets")

    const { errors: validations, path } = validators
      .required("This input is required")
      .isNumber("Value must be a number")
      .min(0, "The minimum value is 0")
      .max(100, "The maximum value is 100")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const validateNumOfAgents = useCallback((value: string) => {
    const validators = new StringValidator(value, "numOfAgents")

    const { errors: validations, path } = validators
      .required("This input is required")
      .isNumber("Value must be a number")
      .min(0, "The minimum value is 0")
      .isInteger("Value must be an integer")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const validateaverageResolutionTimeInHours = useCallback((value: string) => {
    const validators = new StringValidator(
      value,
      "averageResolutionTimeInHours"
    )

    const { errors: validations, path } = validators
      .required("This input is required")
      .isNumber("Value must be a number")
      .min(0, "The minimum value is 0")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const validateAverageHourlyRateForAgent = useCallback((value: string) => {
    const validators = new StringValidator(value, "averageHourlyRateForAgent")

    const { errors: validations, path } = validators
      .required("This input is required")
      .isNumber("Value must be a number")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const validateEmail = useCallback((value: string) => {
    const validators = new StringValidator(value, "email")

    const { errors: validations, path } = validators
      .required("This input is required")
      .isEmail("Value must be a valid email")

    setFormErrors(oldState => ({ ...oldState, [path]: validations[0] ?? "" }))

    return validations.length ? validations[0] : ""
  }, [])

  const handleSubmit = useCallback<SubmitHandler<FormData>>(
    data => {
      const validations = {
        numOfTicketsPerMonth: validateNumOfTicketsPerMonth(
          data.numOfTicketsPerMonth
        ),
        percentageOfScalableTickets: validatePercentageOfScalableTickets(
          data.percentageOfScalableTickets
        ),
        numOfAgents: validateNumOfAgents(data.numOfAgents),
        averageResolutionTimeInMinutes: validateaverageResolutionTimeInHours(
          data.averageResolutionTimeInHours
        ),
        averageHourlyRateForAgent: validateAverageHourlyRateForAgent(
          data.averageHourlyRateForAgent
        ),
        email: validateEmail(data.email),
      }

      if (Object.values(validations).some(error => !!error.length)) {
        const formErrors = Object.fromEntries(
          Object.entries(validations).filter(([, error]) => !!error.length)
        )

        formRef.current?.setErrors(formErrors)
        return
      }

      const results = calculateROI({
        averageHourlyRateForAgent: Number(
          data.averageHourlyRateForAgent
        ).valueOf(),
        averageResolutionTime: Number(data.averageResolutionTimeInHours)
          .valueOf()
          .valueOf(),
        manualAgentTickets: data.manualAgentTickets,
        numOfAgents: Number(data.numOfAgents).valueOf(),
        numOfTicketsPerMonth: Number(data.numOfTicketsPerMonth).valueOf(),
        percentageOfScalableEngineeringTickets: Number(
          data.percentageOfScalableTickets
        ).valueOf(),
      })
      console.log(results)
      logAmplitudeEvent("ROI Calculator", { ...data })

      navigate("/roi-results", {
        state: { results },
      })
    },
    [
      validateAverageHourlyRateForAgent,
      validateaverageResolutionTimeInHours,
      validateEmail,
      validateNumOfAgents,
      validateNumOfTicketsPerMonth,
      validatePercentageOfScalableTickets,
    ]
  )

  return (
    <Layout
      keywords="ROI, proactive customer support, irisagent"
      title="Calculate The ROI Of Using of AI And GPT-Powered Customer Support"
      description="Check out how you can calculate the ROI of using AI and GPT-Powered customer service. Learn how it can help you reduce both the overall complaints and resolution time."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/roi-calculator/" />
      </Helmet>
      <section className="app-roi-section">
        <h1>
          Calculate the <span className="highlight">ROI of Proactive AI</span>
        </h1>
        <div className="app-roi-content">
          <div className="app-roi-form-wrapper">
            <Form
              initialData={{ averageHourlyRateForAgent: "50" } as FormData}
              onSubmit={handleSubmit}
            >
              <InputField
                name="numOfTicketsPerMonth"
                label="# of tickets/month"
                validate={validateNumOfTicketsPerMonth}
                validateWhileType
                required
                placeholder="eg. 5000"
              />

              <InputField
                name="percentageOfScalableTickets"
                label="% of tickets that escalate to engineering"
                validate={validatePercentageOfScalableTickets}
                validateWhileType
                required
                placeholder="0-100"
              />

              <InputField
                name="numOfAgents"
                label="# of agents"
                validate={validateNumOfAgents}
                validateWhileType
                required
                placeholder="0-10000"
              />

              <ToggleInput
                name="manualAgentTickets"
                label="Do your agents manually tag tickets"
              />

              <InputField
                name="averageResolutionTimeInHours"
                label="Average resolution time (in hrs)"
                validate={validateaverageResolutionTimeInHours}
                validateWhileType
                required
                placeholder="eg. 2"
              />

              <InputField
                name="averageHourlyRateForAgent"
                label="Average hourly rate for agent (in USD)"
                tip={
                  <>
                    Reference: Indeed.com. check country hourly rate from{" "}
                    <a
                      target="_blank"
                      href="https://www.indeed.com/career/customer-support-representative/salaries"
                    >
                      this link
                    </a>
                  </>
                }
                validate={validateAverageHourlyRateForAgent}
                validateWhileType
                required
                placeholder="eg. 50"
              />

              <InputField
                name="email"
                label="Your email address"
                validate={validateEmail}
                validateWhileType
                required
                placeholder="Your email address"
              />

              <InputField
                name="phoneNumber"
                label="Your phone number"
                validateWhileType
                required
                placeholder="Your phone number"
              />

              <button
                disabled={
                  Object.keys(formErrors).length === 6
                    ? !Object.values(formErrors).every(
                        formError => !formError.length
                      )
                    : true
                }
                type="submit"
              >
                Calculate ROI
              </button>
            </Form>
          </div>

          <div className="app-roi-img-wrapper">
            <img src="/img/roi-calculator.webp" alt="ROI Calculator" />
          </div>
        </div>
      </section>
    </Layout>
  )
}
